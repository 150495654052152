/* common */

html,
body,
#root {
  height: 100%;
}

body {
  background-color: #f3f3f4;
  overflow-x: auto;
}

.awesomplete {
  width: 100%;
}

.awesomplete > ul > li {
  white-space: nowrap;
}

.custom-file-label::after {
  content: 'Выбрать' !important;
}

.modal {
  overflow-y: scroll;
}

.modal-footer {
  min-height: 71px;
}

.noselect {
  user-select: none;
}

.opacity-10 {
  opacity: 0.1;
}

.overflow-scroll {
  overflow: scroll;
}

.table {
  table-layout: fixed;
}

.table-hover td {
  cursor: pointer;
}

.text-lt {
  text-decoration: line-through;
}

.table-accent-bg {
  background-color: rgba(0, 0, 0, 0.05);
}

.w-30px {
  width: 30px;
}

.w-40px {
  width: 40px;
}

.w-60px {
  width: 60px;
}

.w-80px {
  width: 80px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-140px {
  width: 140px;
}

.w-160px {
  width: 160px;
}

.w-180px {
  width: 180px;
}

.w-200px {
  width: 200px;
}

.w-220px {
  width: 220px;
}

.w-300px {
  width: 300px;
}

@media (max-width: 992px) {
  h3,
  h4 {
    font-size: 1.25rem;
  }
}

/* components */

.CheckBox i {
  color: #212529;
  font-size: 1.3rem;
  margin-right: 5px;
  margin-top: 2px;
}

.CheckBox._white i {
  color: #fff;
}

.CheckBox label {
  align-items: flex-start;
  display: flex;
}

.CheckBox input {
  display: none;
}

.CheckBox input:disabled + label {
  cursor: default;
  opacity: 0.5;
}

.CheckListCard {
  transition: all 0.3s ease-in-out;
}

.CurrentTimeLine {
  background-color: rgba(255, 0, 0);
  height: 2px;
  position: absolute;
  right: 0;
  top: 300px;
  width: 70%;
  z-index: 1;
}

.CurrentTimeLine:before {
  background-color: #ff0000;
  border-radius: 50%;
  content: '';
  height: 8px;
  position: absolute;
  top: 1px;
  transform: translate(-50%, -50%);
  width: 8px;
}

.DirPicker_separator {
  min-width: 2rem;
}

.DirTree_item i {
  font-size: 0.5rem;
}

.DirTree_item_active {
  font-weight: bold;
}

.DirTree_indent2 {
  min-width: 1rem;
  width: 1rem;
}

.DirTree_indent3 {
  min-width: 2rem;
  width: 2rem;
}

.DirTree_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DirTree_name:hover {
  cursor: pointer;
}

.DirTree_item_active .DirTree_name:hover {
  cursor: default;
}

.DirTree_editIcon {
  opacity: 0;
}

.DirTree_editIcon:hover {
  cursor: pointer;
}

.DirTree_item:hover .DirTree_editIcon {
  opacity: 0.5;
}

.FormGroup_inputColor {
  position: relative;
}

.FormGroup_inputColorIcon {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  z-index: 4;
}

.Grid th {
  text-align: center;
}

.Grid th,
.Grid td {
  line-height: 29px;
  text-align: center;
}

.Grid input,
.Grid select {
  border: 0;
  border-radius: 0;
  text-align: center;
}

.Grid .Grid_cellMergeRight {
  border-right-color: transparent;
}

.Grid .Grid_cellTextLeft {
  text-align: left;
}

.Grid .Grid_cellTextRight {
  text-align: right;
}

.Grid .Grid_cellTextBold {
  font-weight: 700;
}

.Grid .Grid_cellTextItalic {
  font-style: italic;
}

.Grid .Grid_cellTextMuted {
  color: #afb3b7;
}

.FilterButton_clearBtn {
  max-width: 40px;
}

.FilterButton._disabled .btn {
  opacity: 0.65;
}

.FilterButton_clearBtn.disabled,
.FilterButton_clearBtn:disabled {
  opacity: 1;
}

.FilterButton_clearBtn.disabled *,
.FilterButton_clearBtn:disabled * {
  opacity: 0.5;
}

.HolidayCalendar td._empty {
  color: rgba(0, 0, 0, 0.2);
}

.HolidayCalendar td:hover:not(._empty) {
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.List_button {
  background-color: transparent;
  border: none;
  color: #dee2e6;
}

.List_button:focus,
.List_button:active {
  outline: none;
}

.List .table-success .List_button {
  color: #afb3b7;
}

.List .table-danger .List_button {
  color: #afb3b7;
}

.List_button:hover {
  color: #212529 !important;
}

.List_rowWithTopSeparator td {
  border-top-width: 4px;
}

.ModalNav {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #e9ecef;
}

.MobileSchedulerTable table {
  margin-bottom: 0;
}

.MobileSchedulerTable th:first-of-type,
.MobileSchedulerTable td:first-of-type {
  width: 30%;
}

.MobileSchedulerTable thead tr {
  height: 40px;
}

.MobileSchedulerTable tbody tr {
  height: 78px;
}

.MobileSchedulerTable_slider {
  height: 100%;
  right: 0;
  top: 0;
  width: 70%;
  z-index: 3;
}

.MobileSchedulerTable_slider .swiper-container {
  height: 100%;
  width: 100%;
}

.MobileSchedulerTable._holiday {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, #fbfbfb 10px, #f5f5f5 20px);
}

.MobileSchedulerTable_slider h4 {
  line-height: 40px;
  margin: 0;
}

.PageWrapper {
  overflow-y: scroll;
}

.SchedulerCalendar {
  position: relative;
}

.SchedulerCalendar td {
  user-select: none;
}

.SchedulerCalendar td._active {
  background-color: #fff192;
}

.SchedulerCalendar_arrow,
.SchedulerCalendar_search {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 37px;
  justify-content: center;
  position: absolute;
  width: 37px;
}

.SchedulerCalendar_arrow._prev {
  left: 1px;
  top: 1px;
}

.SchedulerCalendar_arrow._next {
  left: 38px;
  top: 1px;
}

.SchedulerCalendar_search {
  right: 1px;
  top: 1px;
}

.SchedulerCalendar_arrow:hover {
  opacity: 0.8;
}

.SchedulerTable._holiday {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, #fbfbfb 10px, #f5f5f5 20px);
}

.SchedulerTable table {
  min-width: 700px;
}

.SchedulerTable th:first-of-type,
.SchedulerTable td:first-of-type {
  width: 10%;
}

.SchedulerTable td {
  position: relative;
}

.SchedulerTable tbody tr {
  height: 78px;
}

.ScrollGrid {
  height: 334px;
  overflow-y: scroll;
}

.ScrollGrid .ScrollGrid_cellHighlightLeft {
  border-left: 4px solid #212529;
}

.RecordCard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px grey;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.9;
  overflow: hidden;
  padding: 5px 5px;
  min-height: 69px;
}

.RecordCard._empty {
  justify-content: center;
  left: 5px;
  opacity: 0;
  right: 12px;
  text-decoration: underline;
  z-index: 1;
}

.RecordCard_copyButton {
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
}

.SchedulerTable td .RecordCard:hover {
  opacity: 1;
}

.RecordCard {
  position: absolute;
}

.RecordCard span {
  font-size: 0.8rem;
}

.RecordCard_fade {
  bottom: 0;
  height: 50px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.RecordCard.table-success .RecordCard_fade {
  background-image: linear-gradient(to bottom, rgba(195, 230, 203, 0), rgba(195, 230, 203, 1) 80%);
}

.RecordCard.table-primary .RecordCard_fade {
  background-image: linear-gradient(to bottom, rgba(184, 218, 255, 0), rgba(184, 218, 255, 1) 80%);
}

.RecordCard.table-warning .RecordCard_fade {
  background-image: linear-gradient(to bottom, rgba(255, 237, 184, 0), rgba(255, 237, 184, 1) 80%);
}

.RecordCard.table-secondary .RecordCard_fade {
  background-image: linear-gradient(to bottom, rgba(214, 216, 219, 0), rgba(214, 216, 219, 1) 80%);
}

.RecordCard.table-danger .RecordCard_fade {
  background-image: linear-gradient(to bottom, rgba(245, 198, 203, 0), rgba(245, 198, 203, 1) 80%);
}

.Sidebar {
  background-color: #2f4050;
  min-width: 64px;
  padding-top: 54px;
}

.Sidebar_item {
  align-items: center;
  color: rgb(167, 177, 194);
  display: flex;
  height: 64px;
  justify-content: center;
  position: relative;
}

.Sidebar_item:hover {
  color: #fff;
  text-decoration: none;
}

.Sidebar_item_active {
  background-color: rgb(37, 51, 64);
}

.Sidebar_item_active::before {
  background-color: rgb(28, 179, 148);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.WorkloadTable {
  border-collapse: collapse;
  color: #212529;
  table-layout: fixed;
  text-align: center;
  width: 100%;
}

.WorkloadTable td,
.WorkloadTable th {
  border: 1px solid #dee2e6;
  padding: 0;
}

.WorkloadTable td._filled {
  background-color: #c6c6c6;
}

.WorkloadTable th {
  font-weight: normal;
}

.WorkloadTable th:first-of-type,
.WorkloadTable td:first-of-type {
  width: 10%;
}

.WorkloadTable td._active {
  background-color: #d3f0d2;
}

@media (max-width: 991px) {
  .PageWrapper._noMobilePadding > .card > .card-body {
    padding: 0;
  }
  .PMyOrders .table {
    margin-bottom: 0;
  }
  .PMyOrders .table thead th {
    border-top: 0;
  }
  .RecordCard {
    margin-top: 4px;
    margin-bottom: 9px;
  }
  .SchedulerCalendar table {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 14px;
  }
  .SchedulerCalendar table td,
  .SchedulerCalendar table th {
    padding: 0.5rem;
  }
  .SchedulerCalendar table thead th {
    border-top: 0;
  }
  .SchedulerCalendar table tbody td:first-of-type {
    border-left: 0;
  }
  .SchedulerCalendar table tbody td:last-of-type {
    border-right: 0;
  }
  .SchedulerCalendar table thead th:first-of-type {
    border-left: 0;
  }
  .SchedulerCalendar table thead th:last-of-type {
    border-right: 0;
  }
  .SchedulerChart {
    margin: 0 auto;
    max-width: 400px;
  }
  .Topbar_collapse {
    z-index: 1000;
  }
  .Topbar_menuButton {
    background-color: transparent;
    border: none;
  }
  .Topbar_menuButton:focus,
  .Topbar_menuButton:active {
    outline: none;
  }
}

@media (min-width: 992px) {
  .CheckListCard {
    width: 375px;
  }
  .CurrentTimeLine {
    width: 90%;
  }
  .RecordCard {
    bottom: 5px;
    position: absolute;
    top: 4px;
    z-index: 2;
  }
  .RecordCard:hover .RecordCard_copyButton {
    display: block;
  }
  .RecordCard._empty:hover {
    opacity: 0.4 !important;
  }
  .RecordCard_copyButton {
    display: none;
  }
  .SchedulerCalendar {
    position: relative;
    width: 570px;
  }
  .SchedulerCalendar td:hover:not(._active) {
    background-color: #ffffd7;
    cursor: pointer;
  }
  .SchedulerCalendar_arrow._prev {
    left: 6px;
    top: 6px;
  }
  .SchedulerCalendar_arrow._next {
    left: 43px;
    top: 6px;
  }
  .SchedulerCalendar_search {
    right: 6px;
    top: 6px;
  }
  .SchedulerChart {
    width: 400px;
  }
  .VirtualChart {
    opacity: 0;
    transition: opacity 1s;
    width: 300px;
  }
  .VirtualChart._fade {
    opacity: 1;
  }
  .Topbar {
    min-height: 55px;
  }
}

/* modals */

.MActivityEdit .modal-body {
  height: 386px;
}

.MActivityPicker .modal-body {
  min-height: 505px;
}

.MActivityPicker .table {
  margin: 0;
}

.MActivityPicker .pagination {
  margin: 0;
}

.MClaimEdit .modal-body {
  min-height: 398px;
}

.MContractorView .modal-body {
  min-height: 240px;
}

.MHandoverAct .modal-body {
  min-height: 415px;
}

.MOrderEdit .modal-body {
  min-height: 398px;
}

.MProductEdit .modal-body {
  height: 432px;
}

.MProductEdit_image {
  max-height: 400px;
}

.MProductPicker .modal-body {
  min-height: 505px;
}

.MProductPicker .table {
  margin: 0;
}

.MProductPicker .pagination {
  margin: 0;
}

.MRecordEdit_infoButton {
  width: 40px;
}

.MStockMovementEdit .modal-body {
  min-height: 350px;
}

.MVehicleView .modal-body {
  min-height: 420px;
}

/* pages */

.PLogin .card {
  width: 350px;
}

@media (min-width: 992px) {
  .PMyOrder_buttons {
    width: 375px;
  }
}
